/* working hours component */
.working-hours {
    border-collapse: collapse;
    width: auto;
}
/* headers */
.hours td {
    text-align: left;
    vertical-align: bottom;
    padding: 0;
}
.hours .header {
    width: 20px;
    max-width: 20px !important;
}
.hours .header span {
    font-size: 14px;
    display: block;
    overflow: hidden;
}
.hours .part {
    text-align: center;
}
.hours .part span {
    font-size: 9px;
    display: block;
    overflow: hidden;
}
/* individual days */
.day {
   /* time cells */
   /* overlay showing selected timespans */
}
.day td {
    height: 45px;
    padding: 0;
}
.day .day-name {
    padding: 0px 10px 0px 0px;
    vertical-align: middle;
    font-size: 14px;
}
.day .reset {
    padding-left: 10px;
    vertical-align: middle;
}
.day .reset .working-hours-reset {
    margin-right: 0px;
    border: 1px solid #2FAC66;
    border-radius: 2px;
    background-color: #2FAC66;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    color: #fff;
}
.day .time-cell {
    border: 1px solid silver;
    cursor: pointer;
}
.day .time-cell.selected {
    background-color: #007bff;
}
.day .time-cell.hour {
    border-right: 1px solid #e0e0e0;
}
.day .time-cell.half {
    border-left: 1px solid #e0e0e0;
}
.day .timespan-container {
    position: relative;
    width: 0;
    height: 0;
    padding: 0;
}
.day .timespan-container .timespan-overlay {
    position: absolute;
    height: 15px;
    width: 490px;
    left: 80px;
    text-align: center;
    vertical-align: middle;
    color: black;
    pointer-events: none;
}
.day .timespan-container .timespan-overlay .timespan {
    opacity: 1;
    font-size: 14px;
    border-radius: 5px;
    padding: 5px 5px;
    color: white;
    background-color: #2FAC66;
}
/* reset all feature */
.reset-all {
    text-align: right;
    padding-right: 0px;
    padding-top: 10px;
    
}
.reset-all .working-hours-reset {
    margin-right: 0px;
    border: 1px solid #2FAC66;
    border-radius: 2px;
    background-color: #2FAC66;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    color: #fff;
}
/* admin only styles */
.working-hours .time-cell.selected {
    background-color: #5e6469;
}
.working-hours .timespan-container .timespan-overlay .timespan {
    background-color: #2FAC66;
}
.working-hours button.working-hours-reset {
    padding: 3px 5px;
}
